import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@/assets/styles/style.css";
import "@/assets/styles/select2.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

//axios.defaults.baseURL = "https://micropos1.api.linco.lk/api/";
axios.defaults.baseURL = "https://api.amfinvestmentlanka.com/api/";
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

import Notifications from "@kyvg/vue3-notification";

const app = createApp(App);
//app.config.globalProperties.$HostUrl = "https://micropos1.api.linco.lk/";
app.config.globalProperties.$HostUrl = "https://api.amfinvestmentlanka.com/";
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(Notifications);
app.use(VueSweetalert2);

app.mount("#app");
